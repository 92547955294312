import {ListGroup, Button, Navbar, Nav} from 'react-bootstrap';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaListUl } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { MdOutlineAccessTimeFilled } from "react-icons/md";

function Sidebar ({ onButtonClick }) {
  return (
    <div className="sidebar">
      <Button variant="flat" className="w-100 d-flex justify-content-center btn-toggle" onClick={onButtonClick}>
        <GiHamburgerMenu size="24" />
      </Button>
      <Navbar>
        <Nav.Link href="#" className="active">
          <FaListUl size="18" />
          <span>Full List</span>
        </Nav.Link>
        <Nav.Link href="#">
          <FaUsers size="18" />
          <span>Workers</span>
        </Nav.Link>
        <Nav.Link href="#">
          <MdOutlineAccessTimeFilled size="18" />
          <span>Jobs</span>
        </Nav.Link>
      </Navbar>
    </div>
  );
}

export default Sidebar;