import Header from './header';
import Footer from './footer';
import Dashboard from '../Pages/dashboard';

function Main() {
  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <Dashboard />
      </div>
      <Footer />
    </div>
  );
}

export default Main;