import {Container, Navbar, Button } from 'react-bootstrap';
import { IoMdLogOut } from "react-icons/io";

function Header() {
  return (
    <div className="header">
      <h2 className="page-title">Dashboard</h2>
      <div className="right-header">
        <span>MR</span>
        <p className="mx-2 mb-0 d-none d-md-block">Mike</p>
        <Button variant="flat" className="ms-0 ms-md-2 d-flex align-items-center"><IoMdLogOut size="24" /></Button>
      </div>
    </div>
  );
}

export default Header;