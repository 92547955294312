import { useState } from 'react';
import {Offcanvas, Button, Tabs, Tab, Table, Form, Row, Col} from 'react-bootstrap';
import { IoIosPin } from "react-icons/io";
import { FaMap, FaRegCalendarAlt, FaHashtag  } from "react-icons/fa";
import { FaTag, FaListUl } from "react-icons/fa6";
import { GrUserWorker } from "react-icons/gr";
import { AiFillDollarCircle } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoIosBriefcase } from "react-icons/io";
import { RiAttachment2 } from "react-icons/ri";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Image } from 'react-bootstrap'
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-date-picker';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

function Dashboard() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const renderFloorplan = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Image 
        className="img-fluid" 
        src="https://balancedarchitecture.com/wp-content/uploads/2021/11/EXISTING-FIRST-FLOOR-PRES-scaled-e1635965923983.jpg" />
    </Tooltip>
  );
  const [value, onChange] = useState(new Date());
  return (
    <>
      <DatePicker onChange={onChange} value={value} />
      <Tabs
        defaultActiveKey="denver"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
        <Tab eventKey="denver" title="Denver">
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div className="d-flex align-items-center">
                      <IoIosBriefcase size="24" className="me-2" />
                      <span>job Description</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <IoIosPin size="24" className="me-2" />
                      <span>Address</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <FaMap size="24" className="me-2" />
                      <span>Zip</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <FaTag size="24" className="me-2" />
                      <span>Place Name</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <FaRegCalendarAlt size="24" className="me-2" />
                      <span>Date Requested</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <GrUserWorker size="24" className="me-2" />
                      <span>Worker</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <AiFillDollarCircle size="24" className="me-2" />
                      <span>Payment</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <FaHashtag size="24" className="me-2" />
                      <span>Job Order</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button onClick={handleShow} variant="light">Carpet(24) Tyle(98) Vinyl(42)</Button>
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start">
                      <span>3555 S Zeno Way</span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFloorplan}
                      >
                        <Button variant="flat" className="px-0 px-lg-3"><LuLayoutDashboard size="24" /></Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>80555</td>
                  <td>Summerfield Condos</td>
                  <td>Oct 23 of 2024</td>
                  <td>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                  <td>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button b2" id="button-13">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">139146</td>
                </tr>
                <tr>
                  <td>
                    <Button onClick={handleShow} variant="light">Carpet(24) Tyle(98) Vinyl(42)</Button>
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start">
                      <span>3555 S Zeno Way</span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFloorplan}
                      >
                        <Button variant="flat" className="px-0 px-lg-3"><LuLayoutDashboard size="24" /></Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>80555</td>
                  <td>Summerfield Condos</td>
                  <td>Oct 23 of 2024</td>
                  <td>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                  <td>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button b2" id="button-13">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">139146</td>
                </tr>
                <tr>
                  <td>
                    <Button onClick={handleShow} variant="light">Carpet(24) Tyle(98) Vinyl(42)</Button>
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start">
                      <span>3555 S Zeno Way</span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFloorplan}
                      >
                        <Button variant="flat" className="px-0 px-lg-3"><LuLayoutDashboard size="24" /></Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>80555</td>
                  <td>Summerfield Condos</td>
                  <td>Oct 23 of 2024</td>
                  <td>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                  <td>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button b2" id="button-13">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">139146</td>
                </tr>
                <tr>
                  <td>
                    <Button onClick={handleShow} variant="light">Carpet(24) Tyle(98) Vinyl(42)</Button>
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start">
                      <span>3555 S Zeno Way</span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFloorplan}
                      >
                        <Button variant="flat" className="px-0 px-lg-3"><LuLayoutDashboard size="24" /></Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>80555</td>
                  <td>Summerfield Condos</td>
                  <td>Oct 23 of 2024</td>
                  <td>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                  <td>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button b2" id="button-13">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">139146</td>
                </tr>
                <tr>
                  <td>
                    <Button onClick={handleShow} variant="light">Carpet(24) Tyle(98) Vinyl(42)</Button>
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start">
                      <span>3555 S Zeno Way</span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFloorplan}
                      >
                        <Button variant="flat" className="px-0 px-lg-3"><LuLayoutDashboard size="24" /></Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>80555</td>
                  <td>Summerfield Condos</td>
                  <td>Oct 23 of 2024</td>
                  <td>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                  <td>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button b2" id="button-13">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">139146</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="las-vegas" title="LasVegas">
          Tab content for LasVegas
        </Tab>
        <Tab eventKey="contact" title="Phoenix">
          Tab content for Phoenix
        </Tab>
      </Tabs>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <FaHashtag size="20" className="me-2" />
                      <span>Job Order</span>
                    </div>
                    <p className="value">139146</p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <GrUserWorker size="20" className="me-2" />
                      <span>Worker</span>
                    </div>
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={function noRefCheck(){}}
                      onSearch={function noRefCheck(){}}
                      onSelect={function noRefCheck(){}}
                      options={[
                        {
                          cat: 'Group 1',
                          key: 'Worker 1'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 2'
                        },
                        {
                          cat: 'Group 1',
                          key: 'Worker 3'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 4'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 5'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 6'
                        },
                        {
                          cat: 'Group 2',
                          key: 'Worker 7'
                        }
                      ]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <FaRegCalendarAlt size="20" className="me-2" />
                      <span>Date Requested</span>
                    </div>
                    <p className="value">Oct 23 of 2024</p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <FaTag size="20" className="me-2" />
                      <span>Place Name</span>
                    </div>
                    <p className="value">Summerfield Condos</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <IoIosBriefcase size="20" className="me-2" />
                      <span>job Description</span>
                    </div>
                    <p className="value">Carpet(24) Tyle(98) Vinyl(42)</p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <IoIosPin size="20" className="me-2" />
                      <span>Address</span>
                    </div>
                    <p className="value">3555 S Zeno Way</p>
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
          <Tabs defaultActiveKey="itemized" className="mb-3">
            <Tab className="itemized" eventKey="itemized" title={<><FaListUl size={16} className="me-2" /> Itemized</>}>
              <div className="table-responsive">
                <Table className="text-nowrap">
                  <tbody>
                    <tr>
                      <td>
                        <Form.Group style={{width: "150px", display: "inline-block"}}>
                          <Form.Label>Type</Form.Label>
                          <Form.Control type="text" value="Carpet" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "150px", display: "inline-block"}}>
                          <Form.Label>Type</Form.Label>
                          <Form.Control type="text" value="Materials" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "150px", display: "inline-block"}}>
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control type="text" value="10" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "100px", display: "inline-block"}}>
                          <Form.Label>Unit</Form.Label>
                          <Form.Control type="text" value="SY" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "400px", display: "inline-block"}}>
                          <Form.Label>Product Description</Form.Label>
                          <Form.Control type="text" value="Commanding Tewwd Ps 806" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "300px", display: "inline-block"}}>
                          <Form.Label>Area</Form.Label>
                          <Form.Control type="text" value="Weathered 101" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group style={{width: "150px", display: "inline-block"}}>
                          <Form.Label>Cost</Form.Label>
                          <Form.Control type="text" value="$ 100.00"  />
                        </Form.Group>
                      </td>
                      <td rowspan="2">
                        <Form.Group style={{width: "250px", display: "inline-block"}}>
                          <Form.Label>Total Cost</Form.Label>
                          <Form.Control as="textarea" rows={4} value="Carpet" />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Label>Type</Form.Label>
                          <Form.Control type="text" value="Carpet" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Type</Form.Label>
                          <Form.Control type="text" value="Materials" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control type="text" value="10" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Unit</Form.Label>
                          <Form.Control type="text" value="SY" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Product Description</Form.Label>
                          <Form.Control type="text" value="Commanding Tewwd Ps 806" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Area</Form.Label>
                          <Form.Control type="text" value="Weathered 101" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label>Cost</Form.Label>
                          <Form.Control type="text" value="$ 100.00"  />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <Form.Group>
                          <Form.Label>Note for Worker</Form.Label>
                          <Form.Control as="textarea" rows={1} value="AM installation preference." />
                          <small className="text-danger">ONLY USE FREIGHT EVEVATOR PLEASE</small>
                        </Form.Group>
                      </td>
                    </tr>
                    
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="floorplan" title={<><RiAttachment2 size={16} className="me-2" /> Floorplan</>}>
              <Image 
                className="img-fluid" 
                src="https://balancedarchitecture.com/wp-content/uploads/2021/11/EXISTING-FIRST-FLOOR-PRES-scaled-e1635965923983.jpg" />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Dashboard;